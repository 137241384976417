import React, { useEffect } from "react";
import "./blogsmore.scss"
import Navbar from '../landing/header/Navbar'
import { Link } from 'react-router-dom'
import Faster from '../landing/Faster/Faster'
import Footer from '../landing/footer/Footer'

const Blogsmore = () => {
       useEffect(() => {
            window.scrollTo(0, 0)
          }, [])
    return (
        <>
            <section className='blogs_more_mains'>
                <div  className='top_blogs_more' >
                <div className='section_new'>
                    <Navbar />
                    <img src='\assets\dummy\blogsmorebg.png' className='img-fluid imgbgmore' />
                    <div className='textual_div'>
                        <h2>June 01, 2024</h2>
                        <h1>A CMO's guide to responsible & results-driven AI</h1>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                    </div>

                </div>
                </div>
               
                <div className='bottomm_more'>
                    <div className='custom-container'>
                        <h3>Vel rerum dolor id pariatur cupiditate non minima perspiciatis vel sapiente magni est nihil nihil quo obcaecati iste</h3>
                        <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore. Sit facere veniam qui dolores autem aut nihil laborum. Vel molestiae ipsa est animi repudiandae et fugit quisquam. Ut rerum velit ab odio aliquid in perferendis consequatur a possimus consequatur qui mollitia saepe ut nostrum adipisci.</p>

                        <div className='img_topdiv'>
                            <img src='\assets\dummy\moreimg.png' className='moreimg img-fluid imgg_bggg' />
                            <img src="\assets\dummy\phonebg.png" alt="img" className='img-fluid imgg_phome d-none' />


                        </div>
                        <h4>Eos voluptatem dolores id</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.</p>
                        <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore. Sit facere veniam qui dolores autem aut nihil laborum. Vel molestiae ipsa est animi repudiandae et fugit quisquam. Ut rerum velit ab odio aliquid in perferendis consequatur a possimus consequatur qui mollitia saepe ut nostrum adipisci.</p>
                        <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore. Sit facere veniam qui dolores autem aut nihil laborum. Vel molestiae ipsa est animi repudiandae et fugit quisquam. Ut rerum velit ab odio aliquid in perferendis consequatur a possimus consequatur qui mollitia saepe ut nostrum adipisci.</p>
                        <h4>Et tempore mollitia nam molestias</h4>
                        <p>Eros dictum ante class sagittis dolor suspendisse pretium. Rutrum amet penatibus velit amet arcu sociosqu vivamus id.</p>
                        <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore. Sit facere veniam qui dolores autem aut nihil laborum. Vel molestiae ipsa est animi repudiandae et fugit quisquam. Ut rerum velit ab odio aliquid in perferendis consequatur a possimus consequatur qui mollitia saepe ut nostrum adipisci.</p>
                        <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore. Sit facere veniam qui dolores autem aut nihil laborum. Vel molestiae ipsa est animi repudiandae et fugit quisquam. Ut rerum velit ab odio aliquid in perferendis consequatur a possimus consequatur qui mollitia saepe ut nostrum adipisci.</p>
                        <h4>Aut beatae nihil ex odit laudantium</h4>
                        <p>At praesent consectetur elit etiam quam vitae. Morbi litora dictum iaculis enim lectus pretium. Dis hendrerit cras platea facilisis a rutrum. Tristique ligula vivamus habitasse imperdiet fringilla dapibus. Aliquet cursus convallis nisi rhoncus tortor neque. Placerat praesent maecenas; primis et eu tincidunt mattis. Aenean dis luctus magnis; potenti augue amet velit.</p>
                        <p>usto molestie orci habitant vehicula sodales phasellus. <span>Scelerisque ridiculus</span> fusce nisi in platea. Sem conubia arcu eleifend aptent adipiscing.</p>
                    </div>
                </div>
                <div className="main-blog">
                <div className='custom-container'>
                    <div className='blogs_texttt new__textual'>
                        <h1>Related Blogs</h1>
                     
                            <button>See All</button>

                      
                    </div>
                    <div className="bottom-cards">
                        <div className="single-card">
                            <div className="main-img">
                                <img src="\assets\dummy\bimg1.png" alt="img" className='img-fluid ' />
                            </div>
                            <div className="bottom-text">
                                <h5>A CMO's guide to responsible & results-driven AI</h5>
                                <h6>Demystify generative AI and its potential to transform marketing strategies across all its functions & roles.</h6>
                                <div className="inner-twice">
                                    <p>June 01, 2024</p>
                                    <a href="#" className='btn-read'>Read More <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5317 9.27765L3.33334 9.27765L3.33334 7.72216L10.5317 7.72216L10.5317 5L15.3333 8.5L10.5317 12L10.5317 9.27765Z" fill="#2C83F6" />
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                        <div className="single-card">
                            <div className="main-img">
                                <img src="\assets\dummy\bimg2.png" alt="img" className='img-fluid' />
                            </div>
                            <div className="bottom-text">
                                <h5>Webflow Integrations: The Essential Tools Every Website Needs</h5>
                                <h6>Here are 7 of the best Webflow integrations to help you improve product pages, generate reviews, auto-generate engaging website copy, and automate email marketi</h6>
                                <div className="inner-twice">
                                    <p>June 01, 2024</p>
                                    <a href="#" className='btn-read'>Read More <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5317 9.27765L3.33334 9.27765L3.33334 7.72216L10.5317 7.72216L10.5317 5L15.3333 8.5L10.5317 12L10.5317 9.27765Z" fill="#2C83F6" />
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                        <div className="single-card">
                            <div className="main-img">
                                <img src="\assets\dummy\bimg3.png" alt="img" className='img-fluid' />
                            </div>
                            <div className="bottom-text">
                                <h5>Best AI Marketing Tools of 2024: Top Picks for Every Marketer</h5>
                                <h6>Explore the best AI marketing tools of 2024. Find top picks, features, and benefits to elevate your marketing strategy, attract leads, and convert prospects.</h6>
                                <div className="inner-twice">
                                    <p>June 01, 2024</p>
                                    <a href="#" className='btn-read'>Read More <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5317 9.27765L3.33334 9.27765L3.33334 7.72216L10.5317 7.72216L10.5317 5L15.3333 8.5L10.5317 12L10.5317 9.27765Z" fill="#2C83F6" />
                                    </svg></a>
                                </div>
                            </div>
                        </div>

                    </div>
                    </div>
                </div>
            </section>
            <Faster/>
            <Footer/>
        </>
    )
}

export default Blogsmore
