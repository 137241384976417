import '../../app.scss';
import React, { useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Features from './Features/Features.js';
import Faster from './Faster/Faster.js';
import Footer from './footer/Footer.js';
import About from './About/About.js';
import Howitworks from './Howitworks/Howitworks.js';
import Satoshigpt from './Satoshigpt/Satoshigpt.js';
import Economicmodel from './Economicmodel/Economicmodel.js';
import Visionmission from './Visionmission/Visionmission.js';
import Blog from './Blog/Blog.js';






function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
  
          <Banner />
          <About />
          <Howitworks/>
          <Features />
          <Satoshigpt />
          <Economicmodel />
          <Visionmission/>
          {/* <Blog/> */}
          <Faster />
          <Footer />
    </>
  );
}

export default Landing;