import React, { useEffect } from "react";
import "./blogsdetails.scss"
import Navbar from '../landing/header/Navbar'
import Faster from '../landing/Faster/Faster'
import Footer from '../landing/footer/Footer'
import { Link } from 'react-router-dom'

const Blogsdetails = () => {
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <section className='details_blogges'>

                <div className='bllogs_details'>
                    <video className='main-banner-video'
                        muted="muted" playsinline="playsinline"
                        autoPlay
                        loop
                        width="100%"
                        id="myVideo">
                        <source src="\assets\videophone.mp4" type="video/mp4" />
                    </video>
                    <Navbar />
                    <div className='latest_blog_text'>
                        <h1>Latest Blogs</h1>
                    </div>
                </div>

                <div className='bottom_top'>
                    <div className='custom-container'>
                        <div className='bottom_section'>
                            <img src='\assets\dummy\aiimg.png' className='img-fluid imgtop_side' />
                            <div className='sectionn_new'>
                                <h2>A CMO's guide to responsible & results-driven AI</h2>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                                <div className='innerbottom_side'>
                                    <h3>June 01, 2024</h3>
                                    <Link to="/blogsmore">
                                    <button>Read More <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6849 11.8054L4.76672 11.8054L4.76672 10.0944L12.6849 10.0944L12.6849 7.09999L17.9667 10.95L12.6849 14.8L12.6849 11.8054Z" fill="#2C83F6" />
                                    </svg></button>
                                    </Link>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="bottom-cards">
                            <div className="single-card">
                                <div className="main-img">
                                    <img src="\assets\dummy\bimg1.png" alt="img" className='img-fluid' />
                                </div>
                                <div className="bottom-text">
                                    <h5>A CMO's guide to responsible & results-driven AI</h5>
                                    <h6>Demystify generative AI and its potential to transform marketing strategies across all its functions & roles.</h6>
                                    <div className="inner-twice">
                                        <p>June 01, 2024</p>
                                        <a href="#" className='btn-read'>Read More <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5317 9.27765L3.33334 9.27765L3.33334 7.72216L10.5317 7.72216L10.5317 5L15.3333 8.5L10.5317 12L10.5317 9.27765Z" fill="#2C83F6" />
                                        </svg></a>
                                    </div>
                                </div>
                            </div>
                            <div className="single-card">
                                <div className="main-img">
                                    <img src="\assets\dummy\bimg2.png" alt="img" className='img-fluid' />
                                </div>
                                <div className="bottom-text">
                                    <h5>Webflow Integrations: The Essential Tools Every Website Needs</h5>
                                    <h6>Here are 7 of the best Webflow integrations to help you improve product pages, generate reviews, auto-generate engaging website copy, and automate email marketi</h6>
                                    <div className="inner-twice">
                                        <p>June 01, 2024</p>
                                        <a href="#" className='btn-read'>Read More <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5317 9.27765L3.33334 9.27765L3.33334 7.72216L10.5317 7.72216L10.5317 5L15.3333 8.5L10.5317 12L10.5317 9.27765Z" fill="#2C83F6" />
                                        </svg></a>
                                    </div>
                                </div>
                            </div>
                            <div className="single-card">
                                <div className="main-img">
                                    <img src="\assets\dummy\bimg3.png" alt="img" className='img-fluid' />
                                </div>
                                <div className="bottom-text">
                                    <h5>Best AI Marketing Tools of 2024: Top Picks for Every Marketer</h5>
                                    <h6>Explore the best AI marketing tools of 2024. Find top picks, features, and benefits to elevate your marketing strategy, attract leads, and convert prospects.</h6>
                                    <div className="inner-twice">
                                        <p>June 01, 2024</p>
                                        <a href="#" className='btn-read'>Read More <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5317 9.27765L3.33334 9.27765L3.33334 7.72216L10.5317 7.72216L10.5317 5L15.3333 8.5L10.5317 12L10.5317 9.27765Z" fill="#2C83F6" />
                                        </svg></a>
                                    </div>
                                </div>
                            </div>
                            <div className="single-card new_cartt">
                                <div className="main-img">
                                    <img src="\assets\dummy\bimg3.png" alt="img" className='img-fluid' />
                                </div>
                                <div className="bottom-text">
                                    <h5>Best AI Marketing Tools of 2024: Top Picks for Every Marketer</h5>
                                    <h6>Explore the best AI marketing tools of 2024. Find top picks, features, and benefits to elevate your marketing strategy, attract leads, and convert prospects.</h6>
                                    <div className="inner-twice">
                                        <p>June 01, 2024</p>
                                        <a href="#" className='btn-read'>Read More <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5317 9.27765L3.33334 9.27765L3.33334 7.72216L10.5317 7.72216L10.5317 5L15.3333 8.5L10.5317 12L10.5317 9.27765Z" fill="#2C83F6" />
                                        </svg></a>
                                    </div>
                                </div>
                            </div>

                            <div className="single-card new_cartt">
                                <div className="main-img">
                                    <img src="\assets\dummy\bimg1.png" alt="img" className='img-fluid' />
                                </div>
                                <div className="bottom-text">
                                    <h5>A CMO's guide to responsible & results-driven AI</h5>
                                    <h6>Demystify generative AI and its potential to transform marketing strategies across all its functions & roles.</h6>
                                    <div className="inner-twice">
                                        <p>June 01, 2024</p>
                                        <a href="#" className='btn-read'>Read More <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5317 9.27765L3.33334 9.27765L3.33334 7.72216L10.5317 7.72216L10.5317 5L15.3333 8.5L10.5317 12L10.5317 9.27765Z" fill="#2C83F6" />
                                        </svg></a>
                                    </div>
                                </div>
                            </div>
                            <div className="single-card new_cartt">
                                <div className="main-img">
                                    <img src="\assets\dummy\bimg2.png" alt="img" className='img-fluid' />
                                </div>
                                <div className="bottom-text">
                                    <h5>Webflow Integrations: The Essential Tools Every Website Needs</h5>
                                    <h6>Here are 7 of the best Webflow integrations to help you improve product pages, generate reviews, auto-generate engaging website copy, and automate email marketi</h6>
                                    <div className="inner-twice">
                                        <p>June 01, 2024</p>
                                        <a href="#" className='btn-read'>Read More <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5317 9.27765L3.33334 9.27765L3.33334 7.72216L10.5317 7.72216L10.5317 5L15.3333 8.5L10.5317 12L10.5317 9.27765Z" fill="#2C83F6" />
                                        </svg></a>
                                    </div>
                                </div>
                            </div>
                            <div className="single-card new_cartt">
                                <div className="main-img">
                                    <img src="\assets\dummy\bimg2.png" alt="img" className='img-fluid' />
                                </div>
                                <div className="bottom-text">
                                    <h5>Webflow Integrations: The Essential Tools Every Website Needs</h5>
                                    <h6>Here are 7 of the best Webflow integrations to help you improve product pages, generate reviews, auto-generate engaging website copy, and automate email marketi</h6>
                                    <div className="inner-twice">
                                        <p>June 01, 2024</p>
                                        <a href="#" className='btn-read'>Read More <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5317 9.27765L3.33334 9.27765L3.33334 7.72216L10.5317 7.72216L10.5317 5L15.3333 8.5L10.5317 12L10.5317 9.27765Z" fill="#2C83F6" />
                                        </svg></a>
                                    </div>
                                </div>
                            </div>
                            <div className="single-card new_cartt">
                                <div className="main-img">
                                    <img src="\assets\dummy\bimg3.png" alt="img" className='img-fluid' />
                                </div>
                                <div className="bottom-text">
                                    <h5>Best AI Marketing Tools of 2024: Top Picks for Every Marketer</h5>
                                    <h6>Explore the best AI marketing tools of 2024. Find top picks, features, and benefits to elevate your marketing strategy, attract leads, and convert prospects.</h6>
                                    <div className="inner-twice">
                                        <p>June 01, 2024</p>
                                        <a href="#" className='btn-read'>Read More <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5317 9.27765L3.33334 9.27765L3.33334 7.72216L10.5317 7.72216L10.5317 5L15.3333 8.5L10.5317 12L10.5317 9.27765Z" fill="#2C83F6" />
                                        </svg></a>
                                    </div>
                                </div>
                            </div>

                            <div className="single-card new_cartt">
                                <div className="main-img">
                                    <img src="\assets\dummy\bimg1.png" alt="img" className='img-fluid' />
                                </div>
                                <div className="bottom-text">
                                    <h5>A CMO's guide to responsible & results-driven AI</h5>
                                    <h6>Demystify generative AI and its potential to transform marketing strategies across all its functions & roles.</h6>
                                    <div className="inner-twice">
                                        <p>June 01, 2024</p>
                                        <a href="#" className='btn-read'>Read More <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5317 9.27765L3.33334 9.27765L3.33334 7.72216L10.5317 7.72216L10.5317 5L15.3333 8.5L10.5317 12L10.5317 9.27765Z" fill="#2C83F6" />
                                        </svg></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Link to="/blogsmore">
                        <div className='seemore_div'>
                            <button>See More</button>
                        </div>
                        </Link>
                    </div>
                </div>

            </section>
            <Faster/>
            <Footer/>
        </>
    )
}

export default Blogsdetails
