import React, { useEffect } from 'react';
import Navbar from '../landing/header/Navbar';
import Faster from '../landing/Faster/Faster';
import Footer from '../landing/footer/Footer';

const Mediakit = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    return (
        <>
            <div className="wrapper-banner mediakit_side" id="faqs">
                <div className="wrapper-structure">
                    <video className='main-banner-video'
                        muted="muted" playsInline="playsInline"
                        autoPlay
                        loop
                        width="100%"
                        id="myVideo">
                        <source src="\assets\videophone.mp4" type="video/mp4" />
                    </video>
                    <Navbar />

                    <div className="mid-side-grid">
                        <section className="faqs-section mediakit_side">
                            <div className="main-content">
                                <h4>Our Media Kit</h4>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <section className='mains_media'>
                <div className='custom-container'>
                    <div className='inner_inte'>
                        <h2>Logo</h2>
                    </div>
                    <div className='our__logo'>
                        <h3>Our Logo</h3>
                        <a href='\Olympus AI logos.zip'>
                            <button>Download Logo</button>
                        </a>
                    </div>
                    <div className='full_llogos'>
                        <div className='img_width'>
                            <h5>Full logo (Vertical)</h5>
                            <img src='\assets\olympuslogoone.svg' className='img-fluid imgsymbol' />
                        </div>
                        <div className='flex_div_bnew'>
                            <div className='symbolll'>
                                <h5>Symbol</h5>
                                <img src='\assets\logotwo.svg' className='img-fluid imgsymbol' />
                            </div>
                            <div className='symbolll'>
                                <h5>Full logo (Horizontal)</h5>
                                <img src='\assets\logothree.svg' className='img-fluid imgsymbol' />
                            </div>
                        </div>
                    </div>
                    <div className='full_llogos'>
                        <div className='img_width'>
                            <h5>Full logo (Vertical)</h5>
                            <img src='\assets\logoblueone.svg' className='img-fluid imgsymbol' />
                        </div>
                        <div className='flex_div_bnew'>
                            <div className='symbolll'>
                                <h5>Symbol</h5>
                                <img src='\assets\logobluetwo.svg' className='img-fluid imgsymbol' />
                            </div>
                            <div className='symbolll'>
                                <h5>Full logo (Horizontal)</h5>
                                <img src='\assets\logobluethree.svg' className='img-fluid imgsymbol' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-container'>
                    <div className='inner_inte colorsss'>
                        <h2>Colors</h2>
                    </div>
                    <div className='our__logo'>
                        <h3>Our Colors</h3>
                    </div>
                    <div className='full_llogos'>
                        <div className='color_divone'>
                            <h4>blue</h4>
                            <h6>#2C83F6</h6>
                        </div>
                        <div className='color_divtwo'>
                            <h4>Gradient</h4>
                            <h6>#2C83F6 - #EDA0FE - #FD7C52</h6>
                        </div>
                    </div>
                    <div className='full_llogosone'>
                        <div className='vbottom_cards'>
                            <h4>Black</h4>
                            <h6>#010102</h6>
                        </div>
                        <div className='vbottom_cards1'>
                            <h4>White</h4>
                            <h6>#ffffff</h6>
                        </div>
                        <div className='vbottom_cards2'>
                            <h4>Light Grey</h4>
                            <h6>#F2F2F2</h6>
                        </div>
                        <div className='vbottom_cards3'>
                            <h4>Dark Grey</h4>
                            <h6>#D6D6DD</h6>
                        </div>
                    </div>
                </div>
                <div className='custom-container'>
                    <div className='inner_inte fonttss'>
                        <h2>Fonts</h2>
                    </div>
                    <div className='our__logo'>
                        <h3>Our Fonts</h3>
                        <a href='\Olympus AI-fonts.zip'>
                            <button>Download Fonts</button>
                        </a>
                    </div>
                    <div className='full_llogosthrd'>
                        <div className='parsi'>
                            <h5>Parsi</h5>
                            <div className='inner_parsi'>
                                <h6>Aa</h6>
                            </div>
                            <h4>& % @ 123 #</h4>
                        </div>
                        <div className='parsi'>
                            <h5>Montserrat</h5>
                            <div className='inner_parsi1'>
                                <h6>Aa</h6>
                            </div>
                            <div className='new_fonnt'>& % @ 123 #</div>
                        </div>
                    </div>
                </div>
            </section>

            <Faster />
            <Footer />
        </>
    );
};

export default Mediakit;
