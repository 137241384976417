import React from 'react'
import './banner.scss';
import Navbar from '../header/Navbar';
import { useTranslation } from 'react-i18next';
const Banner = () => {
    const { t } = useTranslation();
  
  return (
    <>
      <section className='bannermain'>
        <video className='main-banner-video'
          muted="muted" playsinline="playsinline"
          autoPlay
          loop
          width="100%"
          id="myVideo">
          <source src="\assets\videophone.mp4" type="video/mp4" />
        </video>
        <Navbar />
        <div className='banner_outer'>
          <h1>{t('Banner-t1')}</h1>
          <p>{t('Banner-p1')} </p>
          <p>{t('Banner-p2')} </p>
          <div className='buttons_main'>
            <a href="\whitepaper.pdf" target='blank'>
              <button className='getstarted'> {t('Banner-btn')} </button>
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Banner