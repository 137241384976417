import React, { useEffect, useRef } from 'react'
import Accordion from 'react-bootstrap/Accordion';



const FaqsDetail = () => {



    return (
        <>
            <div className="wrapper-withouttopside">
                <div className="wrapper-integration">
                    <div className="wrapper-structure">
                        <div className="left-side-grid" style={{ background: "#fff" }}></div>
                        <div className="mid-side-grid">
                            <section className="faqs-detail">
                                <div className="faqs-parent">
                                    <div className="faqs-heading">
                                        <h6>General Information</h6>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>01. What is Olympus AI?</Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI is a unified AI platform that integrates multiple specialized AI models to perform both digital and real-world tasks. It combines the strengths of leading AI models to provide a comprehensive AI workforce capable of executing complex business operations.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>02. How does Olympus AI differ from other AI platforms?</Accordion.Header>
                                            <Accordion.Body>
                                                Unlike traditional AI platforms that offer limited functionalities, Olympus AI provides a unified ecosystem where specialized AI “employees” can autonomously perform tasks, both digitally and in the real world, using blockchain integration and a token-based economy.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>03. What is a Layer 2 AI?</Accordion.Header>
                                            <Accordion.Body>
                                                A Layer 2 AI refers to an AI system built on top of existing AI models or infrastructure. Olympus AI enhances, optimizes, and adds new functionalities to existing AI models, making them more capable of handling specific tasks efficiently.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>04. What industries can benefit from Olympus AI?</Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI caters to a wide range of industries, including e-commerce, financial services, healthcare, real estate, and more, providing AI solutions tailored to the specific needs of each industry.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>05. How can I get started with Olympus AI?</Accordion.Header>
                                            <Accordion.Body>
                                                To get started with Olympus AI, visit our website and create an account. You can then explore various AI employees, their capabilities, and how they can be integrated into your business operations.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className="faqs-parent">
                                    <div className="faqs-heading">
                                        <h6>Key Features</h6>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>01. What are the key features of Olympus AI?</Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI offers several key features, including multi-model integration, blockchain-powered security, a unified platform for all AI needs, specialized AI employees, smart summarization tools, dynamic image generation, and a customizable brand voice.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>02. How does the multi-model integration work?</Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI integrates over seven cutting-edge AI models, each specialized for different tasks, allowing seamless collaboration between AI employees to tackle complex projects requiring diverse skills.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>03. What is the OAI token?</Accordion.Header>
                                            <Accordion.Body>
                                                The OAI token is the native utility token of Olympus AI, used for accessing AI services, rewarding user engagement, and facilitating secure transactions within the platform.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>04. How does blockchain integration enhance security in Olympus AI?</Accordion.Header>
                                            <Accordion.Body>
                                                Blockchain integration ensures secure transactions and data privacy by using encrypted AI-driven real-world operations handled through a secure crypto wallet, minimizing the risk of fraud or unauthorized access.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>05. What is the AI Collaboration feature?</Accordion.Header>
                                            <Accordion.Body>
                                                The AI Collaboration feature allows multiple AI employees to work together seamlessly on complex tasks. For example, a financial AI can set budget constraints that a marketing AI then uses to develop a cost-effective campaign.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className="faqs-parent">
                                    <div className="faqs-heading">
                                        <h6>AI Employees</h6>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>01. What are AI employees in Olympus AI?</Accordion.Header>
                                            <Accordion.Body>
                                                AI employees are specialized AI models optimized for specific business tasks, such as writing, marketing, customer support, product management, and trading. They function as both consultants and autonomous agents capable of executing tasks.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>02. Can AI employees work together?</Accordion.Header>
                                            <Accordion.Body>
                                                Yes, AI employees can collaborate on tasks to enhance operational efficiency. For instance, Gogol AI (writing expert) and Steve AI (marketing expert) can work together to produce and promote content effectively.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>03. What are some examples of AI employees available on Olympus AI?</Accordion.Header>
                                            <Accordion.Body>
                                                Examples include Gogol AI for content creation, Branson AI for customer support, Edison AI for product management, Steve AI for marketing, and Taylor AI for trading and market analysis.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>04. Can I customize the output of AI employees?</Accordion.Header>
                                            <Accordion.Body>
                                                Yes, users can define a consistent brand voice, tone, and style for content creation across all communications, ensuring that AI employees maintain brand consistency.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>05. How do AI employees execute real-world tasks?</Accordion.Header>
                                            <Accordion.Body>
                                                AI employees can perform real-world tasks using blockchain technology and a crypto-based payment system, allowing them to autonomously make payments, book services, or order goods.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className="faqs-parent">
                                    <div className="faqs-heading">
                                        <h6>Tokenomics</h6>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>01. What is the role of the OAI token in the Olympus AI ecosystem?</Accordion.Header>
                                            <Accordion.Body>
                                                The OAI token is used for accessing AI services, staking for rewards, participating in governance, and facilitating secure transactions within the Olympus AI platform.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>02. How can I earn OAI tokens?</Accordion.Header>
                                            <Accordion.Body>
                                                Users can earn OAI tokens by participating in platform activities such as staking, providing high-quality data, engaging in feedback loops, and contributing to the community.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>03. What is the staking mechanism in Olympus AI?</Accordion.Header>
                                            <Accordion.Body>
                                                Users can stake OAI tokens to earn rewards, receive discounts on AI employee fees, gain early access to new features, and enjoy priority task execution.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>04. How does the token sale structure work?</Accordion.Header>
                                            <Accordion.Body>
                                                The token sale includes a private sale, strategic sale, and public sale phases. The funds raised will be used for platform development, operations, marketing, partnerships, and community rewards.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>05. What is the Proof-of-Value system?</Accordion.Header>
                                            <Accordion.Body>
                                                The Proof-of-Value system incentivizes individuals and partners who contribute measurable value to Olympus AI by rewarding them with OAI tokens for activities like referrals, affiliate marketing, and distribution partnerships.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className="faqs-parent">
                                    <div className="faqs-heading">
                                        <h6>Security and Privacy</h6>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>01. How does Olympus AI ensure data privacy and security?</Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI uses encryption, role-based access controls, multi-factor authentication, and blockchain technology to protect user data and ensure secure transactions.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>02. What measures are in place for compliance with data regulations?</Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI complies with global data protection regulations, including GDPR, CCPA, and HIPAA, ensuring that user data is handled securely and in accordance with legal requirements.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>03. How is user data protected on Olympus AI?</Accordion.Header>
                                            <Accordion.Body>
                                                User data is protected using TLS 1.3 encryption for data in transit, AES-256 encryption for data at rest, and secure handling protocols to prevent unauthorized access.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>04. Is there a bug bounty program?</Accordion.Header>
                                            <Accordion.Body>
                                                Yes, Olympus AI has a bug bounty program to encourage ethical hackers to identify and disclose potential security vulnerabilities, ensuring the platform remains secure.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className="faqs-parent">
                                    <div className="faqs-heading">
                                        <h6>Platform Development and Roadmap</h6>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>01. What is the development roadmap for Olympus AI?</Accordion.Header>
                                            <Accordion.Body>
                                                The roadmap includes four phases: Gogol AI development and MVP launch, platform expansion and public launch, AI employee expansion and ecosystem growth, and enterprise solutions and long-term growth.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>02. What are the future plans for AI employee development?
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI plans to develop specialized AI employees for additional fields such as finance, sports, legal services, and healthcare, further expanding its capabilities.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>03. How does Olympus AI plan to scale its platform?</Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI uses a microservices-based architecture that allows for horizontal scaling, ensuring flexibility, easy integration with existing systems, and the ability to handle increasing workloads.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>04. How does the platform handle updates and new features?                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI uses a Continuous Integration and Deployment (CI/CD) pipeline to implement updates and roll out new features gradually, ensuring stability and optimal performance.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className="faqs-parent">
                                    <div className="faqs-heading">
                                        <h6>Integration and Use Cases</h6>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>01. How can Olympus AI be integrated into existing business systems?</Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI offers an API-first design that allows easy integration with existing business systems and tools. This ensures seamless adoption and enables businesses to leverage AI capabilities without significant changes to their current workflows.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>02. Can Olympus AI handle multilingual tasks?</Accordion.Header>
                                            <Accordion.Body>
                                                Yes, Olympus AI supports multiple languages, allowing businesses to utilize AI capabilities across various global markets for content creation, customer support, and more.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>03. What are some real-world use cases of Olympus AI?</Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI can be used in e-commerce for marketing and inventory management, in financial services for trading and portfolio management, in healthcare for patient scheduling and communications, and in real estate for property management and sales.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>04. How does Olympus AI improve marketing efforts?</Accordion.Header>
                                            <Accordion.Body>
                                                Steve AI, Olympus AI’s marketing expert, develops data-driven marketing strategies, manages campaigns, optimizes brand positioning, and creates targeted content for various channels, enhancing overall marketing efficiency.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className="faqs-parent">
                                    <div className="faqs-heading">
                                        <h6>Pricing and Subscription</h6>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>01. How much does it cost to use Olympus AI?</Accordion.Header>
                                            <Accordion.Body>
                                                Pricing for Olympus AI services depends on the AI employees utilized and the level of service required. Access to services is enabled by staking or spending OAI tokens. Contact sales for a customized quote based on your business needs.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>02. Are there any free trials or demos available?                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI may offer free trials or demos for select AI employees and features. Users can sign up on the website to receive updates on trial availability and promotional offers.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>03. How does subscription work on Olympus AI?</Accordion.Header>
                                            <Accordion.Body>
                                                Subscriptions on Olympus AI are managed through OAI tokens. Users can stake tokens to access AI employees and features. Higher staking levels unlock more advanced capabilities and benefits.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>04. Is there a refund policy for unused services?</Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI does not offer refunds for staked or spent tokens. However, users can manage their staking and usage to maximize the value they receive from the platform.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className="faqs-parent">
                                    <div className="faqs-heading">
                                        <h6>Community and Support</h6>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>01. How can I join the Olympus AI community?</Accordion.Header>
                                            <Accordion.Body>
                                                You can join the Olympus AI community through various channels such as Telegram and social media platforms. Stay updated with the latest news, participate in discussions, and engage with other users and developers.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>02. What kind of support does Olympus AI provide?                                          </Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI offers comprehensive support through dedicated customer service AI, Branson AI, which provides 24/7 multi-channel support via chat, email, and ticketing systems.

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>03. How can I provide feedback or suggestions to Olympus AI?                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Olympus AI values community input. Users can provide feedback through community forums, support channels, or directly through the platform’s feedback feature.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>04. Are there any community reward programs?</Accordion.Header>
                                            <Accordion.Body>
                                                Yes, Olympus AI offers community rewards for active participation, including contributing high-quality data, providing valuable feedback, and helping improve AI models. Participants are rewarded with OAI tokens.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </section>
                        </div>
                        <div className="right-side-grid" style={{ background: "#fff" }}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FaqsDetail
