
import React, { useEffect, useRef, useState } from "react";
import "./faqs.scss"
import Navbar from "../landing/header/Navbar";
import { useTranslation } from "react-i18next";
import FaqsDetail from "./FaqsDetail";
import Faster from "../landing/Faster/Faster";
import Footer from "../landing/footer/Footer";




const Faqs = () => {



    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])





    return (
        <>

            <div className="wrapper-banner" id="faqs">
                <div className="wrapper-structure">
                    <video className='main-banner-video'
                        muted="muted" playsinline="playsinline"
                        autoPlay
                        loop
                        width="100%"
                        id="myVideo">
                        <source src="\assets\videophone.mp4" type="video/mp4" />
                    </video>
                    <Navbar />

                    <div className="mid-side-grid">
                        <section className="faqs-section">
                            <div className="main-content">
                                <h6>Frequently Asked Questions</h6>
                                <h4>Expert Answers to Your Most Common Questions</h4>
                                <p>Find answers to the most common questions and get insights into our services, processes, and policies to help you make informed decisions.</p>
                            </div>
                        </section>
                    </div>

                </div>
            </div>
            <FaqsDetail />
            <Faster />
            <Footer />
        </>
    );
};

export default Faqs;
